<template>
  <div v-if="componentData">
    <div
      v-for="category in componentData.data.categories"
      :key="category.name"
      class="category"
    >
      <h2 :id="toSlug(category.name)" class="category__title">
        {{ category.name }}
      </h2>
      <span class="category__desc">{{ category.desc }}</span>

      <div
        v-for="section in category.sections"
        :key="section.name"
        class="section"
      >
        <h3
          :id="toSlug(category.name) + '-' + toSlug(section.name)"
          class="section__title"
        >
          {{ section.name }}
        </h3>

        <div
          v-for="element in section.elements"
          :key="element.name"
          class="section__elements"
        >
          <router-link
            :to="{
              name: 'components',
              params: {
                category: toSlug(category.name),
                section: toSlug(section.name),
                element: toSlug(element.name),
              },
            }"
            class="section__elements__element"
          >
            <div class="relative bg-100 pt-[50%] overflow-hidden">
              <div
                class="
                  absolute
                  inset-0
                  w-full
                  h-full
                  rounded-t-lg
                  overflow-hidden
                "
              >
                <img
                  src="https://tailwindui.com/img/category-thumbnails/marketing/heroes.png"
                  alt=""
                  class="absolute inset-0 w-full h-full"
                />
              </div>
            </div>
            <div class="py-3 px-4">
              <p class="section__elements__element__title">
                {{ element.name }}
              </p>
              <p class="section__elements__element__desc">
                {{ element.components.length }} components
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import componentData from '@/scripts/components';

export default {
  name: 'home',
  data() {
    return {
      componentData: null,
    };
  },
  mounted() {
    this.componentData = componentData;
  },
  methods: {
    toSlug(str) {
      return str
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  &__title {
    @apply text-2xl font-extrabold tracking-wide;
  }

  &__desc {
    @apply block text-sm text-200 w-full mt-2 pb-5;
  }
}

.section {
  @apply grid grid-cols-3 xl:grid-cols-4 py-8 gap-x-8 gap-y-6 border-t border-100;

  &__title {
    @apply text-100 font-semibold col-span-3 xl:col-span-1;
  }

  &__elements {
    @apply col-span-3 grid sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-y-8 lg:gap-x-8;

    &__element {
      @apply relative bg-200 rounded-lg shadow-sm overflow-hidden ring-1 ring-panel ring-opacity-5;

      &__title {
        @apply text-sm font-medium text-100 mb-1;
      }

      &__desc {
        @apply text-xs font-medium text-300;
      }
    }
  }
}
</style>
